<template>
  <v-card class="mb-4" flat :loading="loading">
    <v-card flat>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-combobox
                v-model="selectedBanco"
                :items="bancos"
                :label="$tc('global.banco', 2)"
                dense
                outlined
                :rules="formRules"
                :loading="loadingBancos"
                required
                item-text="descricao"
                item-value="id"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="cheque.conta"
                :rules="formRules"
                :label="$tc('global.conta')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="cheque.numero"
                :label="$tc('global.numero') + ' - ' + $tc('global.cheque')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="selectedTipo"
                :label="$tc('global.tipo')"
                :items="tipos"
                dense
                :rules="formRules"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="cheque.emissor_nome"
                :label="$tc('global.emitidoPor')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="cheque.emissor_ci"
                :label="$tc('global.documento') + ' ' + $tc('global.emissor')"
                :rules="formRules"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-combobox
                v-model="selectedMoeda"
                :label="$tc('global.moeda')"
                :items="moedas"
                :rules="formRules"
                item-text="sigla"
                item-value="id"
                dense
                outlined
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="valor"
                ref="valor"
                :label="$tc('global.valor')"
                :disabled="!selectedMoeda ? true : false"
                :rules="formRules"
                dense
                outlined
                @blur="atualizarConsolidado"
                v-currency="vCurrencyOptions"
                :error="formaPagamentoValorInputError"
                :error-messages="formaPagamentoValorInputErrorMessage"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <DataField
                hide_details
                :disabled="selectedTipo == 'A VISTA'"
                :data_sync.sync="data_emissao"
                :label="$tc('global.data-emissao')"
                :obrigatorio="formRules ? true : false"
              />
            </v-col>

            <v-col cols="12" md="6">
              <DataField
                hide_details
                :data_sync.sync="cheque.data_vencimento"
                :label="$tc('global.data-vencimento')"
                :obrigatorio="formRules ? true : false"
              />
            </v-col>
          </v-row>
        </v-form>
        <div>
          <div class="d-flex" v-if="selectedMoeda">
            <v-spacer></v-spacer>

            {{ $tc("global.restante") + " " + selectedMoeda.sigla }}
            <span v-if="selectedMoeda.sigla === 'G$'">
              {{ totalRestanteConvertido | guarani }}
            </span>
            <span v-else>
              {{ totalRestanteConvertido | currency }}
            </span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          :disabled="chequeValidado"
          class="button_2 white--text"
          @click="addCheque"
        >
          <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
          {{ $tc("global.validar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";
import { fetchBancos } from "@/api/bancos/bancos.js";
import { chequeIsValid } from "@/api/cheques/cheques.js";
import { format, parseISO } from "date-fns";

export default {
  name: "BaixaLoteFormaPagamentoCheque",

  props: {
    valor_restante: {
      type: Number,
      default: 0,
    },
    moeda_venda: {
      type: String,
      default: null,
    },
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      cheque: {},
      tipos: ["A VISTA", "DIFERIDO"],
      valor: 0,
      bancos: [],
      validForm: true,
      loading: false,
      formRules: [inputRequired],
      loadingBancos: true,
      selectedMoeda: null,
      selectedBanco: null,
      cambio_consolidado: 0,
      consolidado: 0,
      chequeValidado: false,
      formaPagamentoValorInputError: false,
      formaPagamentoValorInputErrorMessage: null,
      selectedTipo: null,
      data_emissao: null,
    };
  },

  watch: {
    valor() {
      if (this.valor && this.cheque.valor != this.valor) {
        this.cheque.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cheque.moeda_id = this.selectedMoeda.id_moeda;
        // this.$emit("update:selected_moeda", this.selectedMoeda);
        this.setCambio();
      }
    },
    selectedBanco() {
      if (this.selectedBanco) {
        this.cheque.banco_id = this.selectedBanco.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.cheque.tipo = this.selectedTipo;
        if (this.selectedTipo == "A VISTA") {
          this.data_emissao = format(new Date(), "yyyy-MM-dd");
        }
      }
    },
    data_emissao() {
      if (this.data_emissao) {
        this.cheque.data_emissao = this.data_emissao;
      }
    },
  },

  computed: {
    disableCheque() {
      let disable = false;
      if (this.cheque) {
        if (this.cheque.status === 1) {
          disable = true;
        } else if (this.cheque.status === 2) {
          disable = true;
        }
      }
      return disable;
    },
    vCurrencyOptions() {
      return {
        precision:
          this.selectedMoeda && this.selectedMoeda.sigla == "G$"
            ? 0
            : undefined,

        distractionFree: false,
      };
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),

    totalRestanteConvertido() {
      let result = this.cambioResolve(
        this.moeda_venda,
        this.valor_restante,
        this.selectedMoeda.sigla,
        this.cambio_consolidado
      );

      return result;
    },
  },

  methods: {
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino * valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem * valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    setCambio() {
      this.cambio_consolidado = 0;

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_venda === "G$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_venda === "R$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_venda === "U$")
      ) {
        this.cambio_consolidado = 1;
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_venda === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_venda === "G$")
      ) {
        if (this.formaPagamentoSelected?.id === 6) {
          this.cambio_consolidado = this.cambio.guarani_credito;
        } else if (this.formaPagamentoSelected?.id === 7) {
          this.cambio_consolidado = this.cambio.guarani_debito;
        } else {
          this.cambio_consolidado = this.cambio.guarani_venda;
        }
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_venda === "R$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_venda === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_real;
      }

      if (
        (this.selectedMoeda.sigla === "R$" && this.moeda_venda === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_venda === "R$")
      ) {
        this.cambio_consolidado = this.cambio.real_venda;
      }
    },

    atualizarConsolidado() {
      if (this.cambio_consolidado > 0) {
        let changeConsolidado = this.cambioResolve(
          this.selectedMoeda.sigla,
          this.$ci.parse(this.valor, this.vCurrencyOptions),
          this.moeda_venda,
          this.cambio_consolidado
        );

        this.cheque.consolidado = changeConsolidado;

        this.validatePagamentoValor();
      }
    },

    validatePagamentoValor() {
      if (!this.cheque.valor) return;

      let valor_restante_aceito = this.moeda_venda === "G$" ? 100 : 0.01;

      if (
        this.cheque.consolidado >
        this.valor_restante + valor_restante_aceito
      ) {
        this.formaPagamentoValorInputError = true;
        this.formaPagamentoValorInputErrorMessage = "Valor maior que restante";
      } else {
        this.formaPagamentoValorInputError = false;
        this.formaPagamentoValorInputErrorMessage = "";
      }
    },

    getBancos() {
      this.loadingBancos = true;
      return fetchBancos()
        .then((response) => {
          this.bancos = response;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
        });
    },

    addCheque() {
      if (this.$refs.form.validate()) {
        let cheque2 = {};

        cheque2.banco_id = this.cheque.banco_id;
        cheque2.conta = this.cheque.conta;
        cheque2.numero = this.cheque.numero;
        cheque2.tipo = this.cheque.tipo;
        cheque2.emissor_nome = this.cheque.emissor_nome;
        cheque2.emissor_ci = this.cheque.emissor_ci;
        cheque2.data_emissao = this.cheque.data_emissao;
        cheque2.data_vencimento = this.cheque.data_vencimento;
        cheque2.moeda_id = this.cheque.moeda_id;
        cheque2.moeda_sigla = this.selectedMoeda.sigla;
        cheque2.valor = this.cheque.valor;
        cheque2.cambio = this.cambio_consolidado;
        cheque2.consolidado = this.cheque.consolidado;

        chequeIsValid(cheque2)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("update:cheque", cheque2);
              this.$emit("update:allowCheque", true);
              this.chequeValidado = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.chequeNumeroExistente"));
            }
          });
      }
    },
  },

  async mounted() {
    await this.getBancos();
    this.moedas.map((moeda) => {
      if (moeda.sigla === "R$") {
        moeda.disabled = true;
      } else {
        moeda.disabled = false;
      }
    });
  },
};
</script>

<style></style>
